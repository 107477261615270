<template>
  <div class="koubei_add">
    <div class="close">
      <i class="el-icon-close" @click="closeAction"></i>
    </div>
    <div class="pl_add">
      <el-upload
        v-loading="loading"
        class="upload-demo"
        ref="upload"
        :action="url"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :file-list="fileList"
        :auto-upload="false"
        :on-success="successAction"
      >
        <el-button slot="trigger" size="small" type="primary"
          >选取文件</el-button
        >
        <el-button
          style="margin-left: 10px"
          size="small"
          type="success"
          @click="submitUpload"
          >上传到服务器</el-button
        >
        <div slot="tip" class="el-upload__tip">
          只能上传excel文件，且不超过500kb
        </div>
      </el-upload>
    </div>
  </div>
</template>
<script>
export default {
  name: "PLUpLoad",
  data: function () {
    return {
      fileList: [],
      url: "",
      loading: false,
    };
  },
  methods: {
    submitUpload() {
      //设置文件的名称
      this.url = this.$IP.IP2 + "/exam/files/6/";
      this.loading = true;
      setTimeout(() => {
        this.$refs.upload.submit();
      }, 10);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    closeAction() {
      this.$router.go(-1);
    },
    successAction(res) {
      if (res.code == 200) {
        this.loading = false;
        this.$alert("批量导入成功", "提示", {
          confirmButtonText: "确定",
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.koubei_add {
  padding: 5px 10px;
  .close {
    text-align: right;
    padding: 5px 30px;
    box-sizing: border-box;
    i {
      font-size: 20px;
      cursor: pointer;
      color: #555;
      &:hover {
        color: #aaff;
      }
    }
  }
}
</style>
